import React, { useRef } from "react";
// import ImageIcons from "../imageComponent/ImageIcons";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaWifi } from "react-icons/fa";
import { MdLiveTv, MdOutlineArrowForward } from "react-icons/md";
import { GoBroadcast } from "react-icons/go";
import { windowScroll } from "../utils/windowScroll";
import { GrServices } from "react-icons/gr";
import { SiIntercom } from "react-icons/si";
import { TbDeviceCctv, TbNetwork } from "react-icons/tb";
import { RiHomeWifiFill } from "react-icons/ri";
import { BsRouter } from "react-icons/bs";
import { TbRouter } from "react-icons/tb";

const Services = () => {
    const sliderRef = useRef(null);

    let settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true,
                }
            }
        ]
    };

    return (
        <>
            <section className="services relative">
                <div className="container m-auto">
                    <div className="flex max-sm:flex-wrap sm:justify-between justify-center gap-[20px] items-end md:mb-[25px] mb-[15px]">
                        <div className="md:max-w-[670px] max-w-full sm:text-left max-sm:text-center">
                            <h2>Our Services</h2>

                            <p className="mb-0">My Internet broadband service providers are known for delivering a wide range of services in order to meet the diverse needs of our customers. Our services include</p>
                        </div>

                        <div className='flex justify-center'>
                            <button type="button" className='h-[40px] w-[40px] bg-[#000] duration-500 hover:bg-[#f48533] text-white cursor-pointer rounded-[10px] flex justify-center items-center text-[22px]' onClick={() => sliderRef.current.slickPrev()}>
                                <IoIosArrowBack />
                            </button>
                            <button type="button" className='ml-[15px] h-[40px] w-[40px] bg-[#000] duration-500 hover:bg-[#f48533] text-white cursor-pointer rounded-[10px] flex justify-center items-center text-[22px]' onClick={() => sliderRef.current.slickNext()}>
                                <IoIosArrowForward />
                            </button>

                        </div>
                    </div>
                    <Slider ref={sliderRef} {...settings}>

                        {servicesData.map((item, i) => (
                            <>
                                {(item.title === "IPTV") ?
                                    (
                                        <Link to="https://www.skypro.co.in" onClick={windowScroll} key={i} className='relative mt-[20px]'>
                                            <div className='relative bg-[#fff] shadow-[0px_0px_15px_rgba(243,132,49,0.4)] duration-500 hover:shadow-[0px_0px_13px_rgba(243,132,49,0.6)] py-[40px] lg:px-[35px] px-[30px] rounded-[10px] mb-[25px] group'>
                                                <div className="relative z-10">
                                                    <div className='flex justify-center items-center mb-[30px] w-[70px] h-[70px] text-[30px] rounded-full text-white bg-[#f38431] group-hover:scale-[1.05] duration-500'>
                                                        {item.servicesIcon}
                                                    </div>

                                                    <h4>{item.title}</h4>
                                                    <p className='mb-0 line-clamp-6'>{item.text}</p>
                                                </div>
                                                <span className='text-[#fff] duration-500 absolute z-20 right-[35px] bottom-[-25px] text-[26px] w-[50px] h-[50px] rounded-full bg-[#000] hover:!bg-[#f48533] hover:!text-[#fff]  text-center justify-center  flex items-center'><MdOutlineArrowForward /></span>
                                            </div>
                                        </Link>
                                    ) : (
                                        (item.title === "Cloud Cam") ?
                                            (
                                                <Link to="https://www.mycloudcam.in/" onClick={windowScroll} key={i} className='relative mt-[20px]'>
                                                    <div className='relative bg-[#fff] shadow-[0px_0px_15px_rgba(243,132,49,0.4)] duration-500 hover:shadow-[0px_0px_13px_rgba(243,132,49,0.6)] py-[40px] lg:px-[35px] px-[30px] rounded-[10px] mb-[25px] group'>
                                                        <div className="relative z-10">
                                                            <div className='flex justify-center items-center mb-[30px] w-[70px] h-[70px] text-[30px] rounded-full text-white bg-[#f38431] group-hover:scale-[1.05] duration-500'>
                                                                {item.servicesIcon}
                                                            </div>

                                                            <h4>{item.title}</h4>
                                                            <p className='mb-0 line-clamp-6'>{item.text}</p>
                                                        </div>
                                                        <span className='text-[#fff] duration-500 absolute z-20 right-[35px] bottom-[-25px] text-[26px] w-[50px] h-[50px] rounded-full bg-[#000] hover:!bg-[#f48533] hover:!text-[#fff]  text-center justify-center  flex items-center'><MdOutlineArrowForward /></span>
                                                    </div>
                                                </Link>
                                            )
                                            : (
                                                < Link to={item.readMore} onClick={windowScroll} key={i} className='relative mt-[20px]'>
                                                    <div className='relative bg-[#fff] shadow-[0px_0px_15px_rgba(243,132,49,0.4)] duration-500 hover:shadow-[0px_0px_13px_rgba(243,132,49,0.6)] py-[40px] lg:px-[35px] px-[30px] rounded-[10px] mb-[25px] group'>
                                                        <div className="relative z-10">
                                                            <div className='flex justify-center items-center mb-[30px] w-[70px] h-[70px] text-[30px] rounded-full text-white bg-[#f38431] group-hover:scale-[1.05] duration-500'>
                                                                {item.servicesIcon}
                                                            </div>

                                                            <h4>{item.title}</h4>
                                                            <p className='mb-0 line-clamp-6'>{item.text}</p>
                                                        </div>
                                                        <span className='text-[#fff] duration-500 absolute z-20 right-[35px] bottom-[-25px] text-[26px] w-[50px] h-[50px] rounded-full bg-[#000] hover:!bg-[#f48533] hover:!text-[#fff]  text-center justify-center  flex items-center'><MdOutlineArrowForward /></span>
                                                    </div>
                                                </Link >
                                            )
                                    )
                                }
                            </>
                        )
                        )
                        }
                    </Slider>
                </div>
            </section >
        </>
    )
}

const servicesData = [
    {
        title: "Broadband",
        text: "Get experience of high-speed broadband solutions and connect to the world around you seamlessly. Our blazing fast internet speed ensures fast downloads, uninterrupted and buffer free streaming and smooth browsing. Take our subscription today and take yourself ahead of the curve.",
        servicesIcon: <FaWifi />,
        readMore: '/pricing/broadband',
    },
    {
        title: "IPTV",
        text: "Enjoy the biggest offer of free IPTV services with our internet connection. Our cutting edge IPTV services provide you with a broad assortment of channels and other content like movies, shows etcetera with just one click.  Enjoy HD-quality streaming and world class entertainment from the comfort of your living room.",
        servicesIcon: <MdLiveTv />,
        readMore: '/service/iptv',
    },
    {
        title: "Lease Line",
        text: "Our services are not only limited to broadband connection, as we also offer reliable and secure lease line services which help you run your operations seamlessly 24/7. Our services are for everyone, be it a small startup or a large corporation. Our lease line services are known for offering faster speed, reliability and stability, thereby allowing you to stay ahead in the marketplace.",
        servicesIcon: <TbRouter />,
        readMore: '/service/internet-leased-line',
    },
    {
        title: "Wifi Hotspot",
        text: "Experience our fast and reliable hotspot services, and stay productive in every corner. We offer our users with secure connectivity for their multiple devices so that they can experience seamless browsing, streaming, gaming and much more. Our commitment to customer satisfaction drives us to provide robust security and round the clock connectivity at affordable prices.",
        servicesIcon: <GoBroadcast />,
        readMore: '/service/free-wifi-hotspot',
    },
    {
        title: "Business Internet Provider",
        text: "My Internet service providers specialise in providing impeccable business internet solutions to help you take your enterprise to new heights.  Take subscription of our high-speed and dependable broadband services for seamless connectivity for all of your business needs and manage your data effortlessly along with enhancing collaboration and driving innovation.",
        servicesIcon: <BsRouter />,
        readMore: '/service/business-internet-provider',
    },
    {
        title: "Home Internet Provider",
        text: "Bring ultimate home internet experience to your home, as our reliable broadband services allow you to enjoy seamless streaming, gaming, working and much more. With us, you will get to experience the perfect blend of speed, reliability and impeccable customer support services. Join our services today to stay connected and entertained.",
        servicesIcon: <RiHomeWifiFill />,
        readMore: '/service/home-internet-provider',
    },
    {
        title: "Managed Network",
        text: "Our comprehensive managed network services have especially been introduced in order to help you accelerate your business operations. Get enhanced security for your network infrastructure along with enhancing its efficiency . With us, every aspect of your network is managed with round the clock monitoring, advanced security measures and seamless integration.",
        servicesIcon: <TbNetwork />,
        readMore: '/service/managed-network',
    },
    {
        title: "Cloud Cam",
        text: "Want unmatchable security and peace of mind for both your home and business? Get experience of our state-of-the art cloud cam facilities, as with these, you can keep close tabs on your property from any corner at any time via your smartphone, laptop, tablet etc. We offer real time alerts, motion detection and secure cloud storage with  our advanced surveillance solutions.",
        servicesIcon: <TbDeviceCctv />,
        readMore: '/service/cloud-cam',
    },
    {
        title: "VoIP Intercom",
        text: "If you want to redefine communication for your business or home, take subscription of our advanced VoIP intercom solutions. Experience clear audio and smooth connectivity for efficient communication for any distance. We offer easy integration, cutting-edge security systems and real-time communication for the enhanced user experience.",
        servicesIcon: <SiIntercom />,
        readMore: '/service/VoIP-intercom',
    },
    {
        title: "CDN Services",
        text: "Get experience of our premier Content Delivery Services and optimise the delivery of your digital content across the globe. We provide fast, reliable and secure distribution of your web asset and reduce latency issues along with improving your user experience. We guarantee providing impeccable performance for your every need be it streaming, serving web pages or delivering software updates.",
        servicesIcon: <GrServices />,
        readMore: '/service/cdn-services',
    },
]
export default Services